<template>
    <div class="warehouse-dialog">
        <CSDialog
            class="tenant-detail-dialog"
            :dialog-visible="newOutWarehouseInfo"
            @onClose="newOutWarehouseInfo = false"
            @onConfirm="jugement('out')"
            dialog-title="添加出库"
        >
            <template>
                <div
                    slot="dialog-content"
                >
                    <div style="max-height:977px;overflow-y:auto;font-size:24px;padding:30px">
                        <div class="preview-tenant" style="line-height: 1;">
                            <p class="field">
                                <span style="margin-top:1px;" class="field-label">物品</span>
                                <CSSelect iWidth="36px" height="40px" style="vertical-align:middle;width:310px">
                                    <select @change="getItemOutPrice()" style="padding-left:5px;width:220px;color: #999999" v-model="newWarehouseDetail.itemName">
                                        <option selected value="">请选择</option>
                                        <option
                                            v-for="item in itemNameList"
                                            :value="item.id"
                                            :key="item.id"
                                        >{{item.name}}</option>
                                    </select>
                                </CSSelect>
                                <span v-show="newWarehouseDetail.itemName !== ''" style="margin-left:20px">该物品当前剩余数量：{{newWarehouseDetail.itemQuanlity}}</span>
                            </p>
                            <p v-show="newWarehouseDetail.itemName !== ''" class="field">
                                <span style="margin-top:1px;" class="field-label">出库单价(元)</span>
                                <CSSelect iWidth="36px" height="40px" style="vertical-align:middle;width:310px;">
                                    <select v-model="newWarehouseDetail.itemOutPrice" style="color: #999999">
                                        <option value="">请选择</option>
                                        <option v-for="(price,index) in outPriceList" :key="index" :value="price.name">{{price.name}}</option>
                                    </select>
                                </CSSelect>
                            </p>
                            <p id="timeField" class="field">
                                <span style="margin-top:1px;" class="field-label">出库时间</span>
                                <CSSelect  iWidth="36px" height="40px" style="vertical-align:middle;width:310px;">
                                    <el-date-picker
                                        style="font-size:18px;width: 270px;"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                        placeholder="请选择"
                                        v-model="newWarehouseDetail.itemOutDate"
                                        :editable="false"
                                    ></el-date-picker>
                                </CSSelect>
                            </p>
                            <p class="field">
                                <span style="margin-top:1px;" class="field-label">出库数量</span>
                                <input style="
                                        width:310px;
                                        height:40px;
                                        padding:0px 10px;
                                        border:1px solid #979797;
                                        border-radius:4px;
                                        line-height:40px;
                                        "
                                        v-model="newWarehouseDetail.itemOutQuantity"
                                        type="text" :placeholder="newWarehouseDetail.itemQuanlity !== 0 ? '限整数1-'+newWarehouseDetail.itemQuanlity  : '无库存'"
                                />
                            </p>
                            <p class="field">
                                <span style="margin-top:1px;" class="field-label">出库原因</span>
                                <CSSelect iWidth="36px" height="40px" style="vertical-align:middle;width:310px;;">
                                    <select style="padding-left:5px;color:#999999" v-model="newWarehouseDetail.itemReason">
                                        <option selected value="">请选择</option>
                                        <option
                                            v-for="reason in itemReasonList"
                                            :value="reason.id"
                                            :key="reason.id">
                                            {{reason.name}}
                                        </option>
                                    </select>
                                </CSSelect>
                            </p>
                            <p id="timeField" class="field">
                                <span style="margin-top:1px;" class="field-label">是否归还</span>
                                <label style="margin-right:15px;">
                                    <input v-model="newWarehouseDetail.itemIsReturn" style="top:2px;margin-right:10px" type="radio" name="return" value="2"/>否
                                </label>
                                <label style="margin-left:15px;">
                                    <input v-model="newWarehouseDetail.itemIsReturn" style="top:2px;margin-right:10px" type="radio" name="return" value="1"/>是
                                </label>
                                <span v-show="newWarehouseDetail.itemIsReturn === '1'">
                                    <span style="margin-left:22px;width:auto;margin-right:20px" class="field-label">归还时间</span>
                                    <CSSelect iWidth="36px" height="40px" style="vertical-align:middle;width:310px;">
                                        <el-date-picker
                                            type="datetime"
                                            format="yyyy-MM-dd HH:mm"
                                            value-format="yyyy-MM-dd HH:mm"
                                            placeholder="请选择"
                                            v-model="newWarehouseDetail.itemReturnDate"
                                            :editable="false"
                                        >
                                        </el-date-picker>
                                    </CSSelect>
                                </span>
                            </p>
                            <p class="field">
                                <span style="margin-top:1px;" class="field-label">领取人</span>
                                <CSSelect iWidth="36px" height="40px" style="vertical-align:middle;width:310px;margin-right:20px;">
                                    <select style="padding-left:5px;color: #999999" v-model="newWarehouseDetail.itemAcpStation"
                                            @change="getAcpStationStaff(newWarehouseDetail.itemAcpStation)">
                                        <option selected value="">请选择</option>
                                        <option
                                            v-for="(acpStation,index) in itemAcpStationList"
                                            :value="acpStation.id"
                                            :key="index"
                                        >
                                            {{acpStation.name}}
                                        </option>
                                    </select>
                                </CSSelect>
                                <CSSelect iWidth="36px" height="40px" v-show="newWarehouseDetail.itemAcpStation !== ''" style="width:310px;vertical-align:middle;">
                                    <select v-model="newWarehouseDetail.itemAcpWorker">
                                        <option selected value="">请选择员工</option>
                                        <option
                                            v-for="(acpStaff,index) in itemAcpWorkerList"
                                            :value="acpStaff.id"
                                            :key="index"
                                        >
                                            {{acpStaff.name}}
                                        </option>
                                    </select>
                                </CSSelect>
                            </p>
                            <p class="field">
                                <span class="field-label">附件</span>
                                <span style="width:500px;display:inline-block;vertical-align: top">
                                    <span style="vertical-align:middle">
                                        <span
                                            v-show="JSON.stringify(newWarehouseDetail.itemFile) !== '[]'"
                                            v-for="(item,index) in newWarehouseDetail.itemFile" :key="index"
                                        >
                                            <span
                                                style="
                                                    width:300px;
                                                    text-overflow:ellipsis;
                                                    overflow:hidden;
                                                    white-space:nowrap;
                                                    vertical-align:middle;
                                                    display:inline-block;
                                                "
                                            >{{item}}</span>
                                            <span
                                                style="vertical-align:middle;"
                                                v-show="JSON.stringify(newWarehouseDetail.itemFile) !== '[]'"
                                                @click="deleteFileList(index)"
                                                class="allow-click"
                                            >
                                                删除
                                            </span>
                                        </span>
                                    </span>
                                    <br v-show="JSON.stringify(newWarehouseDetail.itemFile) !== '[]'"/>
                                    <span
                                        style="vertical-align:super"
                                        class="allow-click"
                                        @click="clickFile()"
                                    >
                                        上传
                                    </span>
                                </span>
                                <input accept="image/*,.xls,.doc,.pdf" @change="selectFile($event)" id="uploadBtn" type="file" hidden/>
                            </p>
                            <p class="field">
                                <span class="field-label">备注</span>
                                <textarea
                                style="
                                        width:580px;
                                        height:180px;
                                        padding:10px;
                                        resize:none;
                                        border:0px;
                                        border-radius:10px;
                                        background-color:#F0F0F0;
                                        vertical-align: top;
                                        "
                                    placeholder="限200个字，非必填" maxlength="200" v-model="newWarehouseDetail.itemNote"
                                ></textarea>
                            </p>
                            <span style="
                                    margin-left:240px;
                                    font-size:20px;
                                    color:#999999;
                                    line-height:1;
                            ">
                                <svg
                                    class="icon"
                                    aria-hidden="true"
                                >
                                    <use xlink:href="#icon-menua-zu92"></use>
                                </svg>
                                确定添加后，将无法修改信息，请仔细认真检查所填信息。
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

    </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";

import { ORG_LEVEL } from "@/constant";

import {
	queryDepartmentUrl,
	queryDepartmentStaffUrl,
    inWarehouseUrl,
} from "@/requestUrl";

export default {
    components:{
        CSSelect,
        CSDialog
    },
    props:{

    },
    data(){
        return {
            newOutWarehouseInfo:false,
            newWarehouseDetail:{
                itemName:"",
                itemOutPrice:"",
                itemOutDate:"",
                itemOutQuantity:"",
                itemReason:"",
                itemIsReturn:"2",
                itemReturnDate:"",
                itemAcpWorker:"",
                itemNote:"",
                itemQuanlity:0,
                itemAcpStation:"",
                itemFile:[],
            },
            outPriceList:[],
            itemNameList: [],
            itemReasonList: [
                {id:1,name:"公共维修"},
                {id:2,name:"客户维修"},
                {id:3,name:"日常维保"},
                {id:4,name:"日常消耗"},
                {id:5,name:"突发事件"},
                {id:6,name:"其它"},
            ],
            itemAcpStationList:[],
            itemAcpWorkerList:[],
        }
    },
    created(){
        this.$vc.on(this.$route.path, "newWarehouseItem","out",(data)=>{
            this.newOutWarehouseInfo = data.boolean;
            this.itemNameList = data.itemList;
            this.getAcpStation();
            var date = this.$vc.dateFormat(new Date().getTime());
            date = date.substr(0,date.length-3);
            this.newWarehouseDetail.itemOutDate = date;
        })
    },
    methods:{
        getItemOutPrice(){
            this.$fly.post(inWarehouseUrl,{
                regionCode:this.$vc.getCurrentRegion().communityId,
                goodsId:this.newWarehouseDetail.itemName,
            }).then(res=>{
                var list = res.data.datas || res.data;
                var delArr1 = [];
                list.forEach(item=>{
                    delArr1.push(item.deliverPrice);
                })
                var delArr2 = Array.from(new Set(delArr1));
                delArr2.forEach((item,index)=>{
                    this.outPriceList.push({id:index+1,name:item})
                })
            })
        },
        deleteFileList(index){
            this.newWarehouseDetail.itemFile.splice(index,1);
        },
        clickFile(){
            $("#uploadBtn").trigger("click")
        },
        selectFile(event){
            const files = event.target.files;
            if(files && files.length > 0){
                const file = files[0];
                this.$fly.putFile(file).then(res => {
                    this.newWarehouseDetail.itemFile.push(res.name)
                })
            }
        },
        getAcpStation(){
            this.$fly.post(queryDepartmentUrl,{
                regionCode:this.$vc.getCurrentRegion().code,
                level:ORG_LEVEL.DEPARTMENT,
            })
            .then(res=>{
                this.itemAcpStationList = res.data;
            })
        },
        getAcpStationStaff(index){
            var stations = this.itemAcpStationList;
            for(var i = 0;i < stations.length;i++){
                if(stations[i].id === index){
                    var departmentCode = stations[i].code;
                    this.$fly.post(queryDepartmentStaffUrl,{
                        regionCode:this.$vc.getCurrentRegion().code,
                        departmentCode,
                    })
                    .then(res=>{
                        res.data.forEach(item=>{
                            if(!item.resignTime){
                                this.itemAcpWorkerList.push(item)
                            }
                        })
                    })
                    break;
                }
            }
        },
        jugement(){
            if(this.jugementWarehouse()){
                var newInfo = this.newWarehouseDetail;
                var isConfirm = true;
                this.$vc.emit(this.$route.path, "newWarehouseItem","done",{
                    isConfirm,
                    itemInfo:newInfo,
                })
                this.newOutWarehouseInfo = false;
                this.newWarehouseDetail = {
                    itemName:"",
                    itemOutPrice:"",
                    itemOutDate:"",
                    itemOutQuantity:"",
                    itemReason:"",
                    itemIsReturn:"2",
                    itemReturnDate:"",
                    itemAcpWorker:"",
                    itemNote:"",
                    itemAcpStation:"",
                    itemQuanlity:0,
                    itemFile:[],
                }
            }
        },
        jugementWarehouse(){
            var itemRealQuantity = this.newWarehouseDetail.itemQuanlity;
            var newReturnDate = this.newWarehouseDetail.itemReturnDate;
            if(this.newWarehouseDetail.itemIsReturn === '1' && !dayjs().isBefore(dayjs(newReturnDate))){
                this.$vc.toast("归还时间有误")
                return false;
            }else if(this.newWarehouseDetail.itemIsReturn === '1' && this.$vc.isEmpty(newReturnDate)){
                this.$vc.toast("请选择归还时间")
                return false;
            }else if(this.newWarehouseDetail.itemOutQuantity > itemRealQuantity || this.newWarehouseDetail.itemOutQuantity < 0){
                this.$vc.toast("出库数量不能大于物品最大值")
                return false;
            }else{
                return this.$vc.validate.validate(
                    {
                        newWarehouse:this.newWarehouseDetail,
                    },
                    {
                        "newWarehouse.itemName":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择物品",
                            }
                        ],
                        "newWarehouse.itemOutPrice":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择出库单价",
                            }
                        ],
                        "newWarehouse.itemOutDate":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择出库时间",
                            }
                        ],
                        "newWarehouse.itemOutQuantity":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请输入出库数量",
                            },
                            {
                                limit:"num",
                                param:"",
                                errInfo:"请输入数量正确格式"
                            },
                        ],
                        "newWarehouse.itemReason":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择选择出库原因",
                            }
                        ],
                        "newWarehouse.itemAcpStation":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择领取部门",
                            }
                        ],
                        "newWarehouse.itemAcpWorker":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择领取人",
                            }
                        ],
                    }
                )
                this.$vc.toast(error)
            }
        }
    },
    watch:{
		'newWarehouseDetail.itemName': function(val){
			this.newWarehouseDetail.itemOutPrice = "";
            this.outPriceList = [];
            this.newWarehouseDetail.itemQuanlity = 0;
            if(val != ""){
                let itemArr = this.itemNameList;
                for(var i = 0;i<=itemArr.length;i++){
                    if(val === itemArr[i].id){
                        this.newWarehouseDetail.itemQuanlity = itemArr[i].currentCount;
                        break;
                    }
                }
            }
		},
		"newWarehouseDetail.itemAcpStation":function(){
			this.newWarehouseDetail.itemAcpWorker = "";
            this.itemAcpWorkerList = [];
		},
        "newWarehouseDetail.itemIsReturn":function(){
            this.newWarehouseDetail.itemReturnDate= "";
        }
	}
}
</script>

<style scoped>
    .tenant-detail-dialog .preview-tenant > p {
        margin-bottom: 20px;
    }

    .tenant-detail-dialog .preview-tenant .field-label {
        vertical-align: middle;
    }

    #timeField .el-input__inner{
        font-size:24px;
    }
    #timeField .el-input__inner::placeholder{
        color:#999;
    }
</style>
